<template>
  <modal-dialog title="CSV Export" :actions="exportActions" @close="$emit('close')">
    <div>
      <p>All your data will be exported into a comma separated (CSV) format that can be opened in your favourite spreadsheet application.</p>
      <p>Note, exporting images is not currently supported.</p>
      <base-input 
        v-model="filename"
        :validation="{ delay: 500, callback: validateFilename }" 
        :options="{
          placeholder: 'export.csv',
        }"></base-input>
    </div>
  </modal-dialog>
</template>

<script>
import { defineComponent } from "vue";
import { downloadCSV } from "../../../api/csvExport.js";

import ModalDialog from "../../controls/ModalDialog.vue";
import CheckInArray from "../../../api/classes/CheckinArray";
import BaseInput from "../../controls/BaseInput.vue";

export default defineComponent({
  name: "export",
  trackAsPageView: true,
  trackWithPageName: "Export",
  emits: ["close"],

  components: {
    ModalDialog,
    BaseInput,
  },

  props: {
    checkIns: CheckInArray,
  },
  methods: {
    validateFilename(filename) {
      return new Promise( (resolve, reject) => {
        if (filename.length == 0 || (filename.length <= 100 & filename.endsWith('.csv'))) {
          resolve();
        } else {
          reject('Invalid filename, must end in .csv and be less than 100 characters');
        }
      });
    },
  },
  data() {
    return {
      filename: "",
      exportActions: [
        {
          isSecondary: true,
          title: "Cancel",
          handle: () => {
            this.$emit("close");
          },
        },
        {
          title: "Export",
          handle: (action) => {
            action.showSpinner = true;
            let vm = this;
            let data = {
              data: vm.checkIns.map((c) => ({ 
                Date: c.date, 
                Day: vm.checkIns.statistics[c.id].daysOnTrailDescription,
                Place: c.title,
                Address: c.address,
                Location: c.location.toString(),
                Distance: c.distanceWalked,
                Notes: c.notes,
                Accommodation: c.accommodation.value,
                RestDays: c.numberOfRestDays,
                ResupplyNotes: c.resupplyNotes,
              })),
              filename: this.filename || "export.csv",
            };
            downloadCSV(data);
            action.showSpinner = false;
          },
        },
      ],
    };
  },
});
</script>

<style scoped>
</style>