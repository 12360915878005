<template>
    <div class="imageViewer" :class="{ fullscreen: fullScreen }">
        <div v-if="firstImage">
          <img 
            class="image-preview" 
            v-if="firstImage" 
            ref="image" 
            :src="firstImage.storageUrl" 
            :alt="firstImage.caption" />
          <div
              v-if="!readOnly || (readOnly && firstImage.caption)"
              class="caption"
          >
            <input
              type="text"
              @input="$emit('update:images', imagesInternal)"
              v-model="firstImage.caption"
              placeholder="Caption (optional)"
            />
          </div>
        </div>
        <div
            v-if="imagesInternal.length == 0 && !readOnly"
            class="imagePlaceholder"
        >
            <div v-show="!isLoadingImage" class="addImage" @click="$refs.fileuploader.browseFile()">
                Add image
            </div>
            <loader v-show="isLoadingImage"></loader>
        </div>
        <div class="controlStrip" v-if="!readOnly">
            <file-uploader
                ref="fileuploader"
                class="control"
                :uploadToPath="uploadPath"
                @uploadstarted="handleUploadStarted"
                @uploaded="handleUploaded"
            ></file-uploader>
            <base-button v-if="firstImage"  @click="showDeleteAlert = true" :isDestructive="true">
              Delete image
            </base-button>
        </div>

        <modal-dialog
            v-if="showDeleteAlert"
            title="Delete Image"
            :actions="deleteActions"
            @close="showDeleteAlert = false"
        >
            <p>You sure you want to delete this image?</p>
        </modal-dialog>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { constants } from "../../constants";
import { storage } from "../../api/firebaseInit";
import { mapState } from "vuex";

import FileUploader from "../controls/FileUploader";
import ModalDialog from "./ModalDialog.vue";
import BaseButton from "./BaseButton.vue";
import Loader from "./Loader.vue";

export default defineComponent({
    name: "ImageViewer",
    emits: ["update:images"],
    components: {
        FileUploader,
        ModalDialog,
        BaseButton,
        Loader,
    },

    props: {
        fullScreen: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        adventureId: String,
        checkInId: String,
        images: {
            type: Object,
            default: null,
        },
    },

    computed: {
        ...mapState({
            user: "user",
        }),
        uploadPath() {
            return this.getImagePath();
        },
        iconOptions() {
            return constants.ICON_OPTIONS.ON_WHITE_ACTIONABLE;
        },
        captionInputOptions() {
            let vm = this;
            return {
                placeholder: "Caption (optional)",
            };
        },
        firstImage() {
          if (!this.readOnly && this.imagesInternal.length > 0) {
            return this.imagesInternal[0];
          }
          return null;
        }
    },

    watch: {
        images() {
            this.bind();
        },
        fullScreen() {
            let options = { ...this.options };
            options.height = this.fullScreen ? "100%" : 200;
            this.options = options;
        },
    },

    data() {
        return {
            isLoadingImage: false,
            showDeleteAlert: false,
            options: {
                rewind: true,
                gap: "0rem",
                cover: true,
                height: 300,
                width: "100%",
                keyboard: false,
            },
            imagesInternal: Object,
            // splide: Object,
            deleteActions: [
                {
                    id: 0,
                    title: "Delete",
                    isDestructive: true,
                    handle: (action) => {
                        let vm = this;
                        action.showSpinner = true;
                        this.delete()
                            .then( () => {
                                action.showSpinner = false;
                            })
                            .catch( err => {
                                //TODO error logging
                                console.log(err);
                                vm.showSpinner = false;
                            })
                            .finally( () => {
                                this.showDeleteAlert = false;
                            })
                    }
                },
                {
                    id: 1,
                    title: "Cancel",
                    isSecondary: true,
                    handle: () => {
                        this.showDeleteAlert = false;
                    },
                },
            ],
        };
    },

    created() {
      this.bind();
    },

    methods: {
        bind() {
            this.imagesInternal = this.images;
        },

        getImagePath() {
            const userId = this.user.uid;

            // get a unique image id
            let imageId = "1";
            if (this.images.length > 0) {
                const ids = this.images.map((image) => {
                    // assume the characters following the final / are the image number
                    const splitName = image.storagePath.split("/");
                    return splitName[splitName.length - 1];
                });
                imageId = Math.max(...ids) + 1;
            }
            const path = `images/${userId}/${this.adventureId}/${this.checkInId}/${imageId}`;

            return path;
        },

        // handleDelete() {
        //   this.showDeleteAlert = true;
        // },

        delete() {
            let vm = this;
            return new Promise( (resolve, reject) => {
                //let currentIndex = vm.$refs.primary.splide.index;
                let currentIndex = 0 //assume always only one image

                let image = vm.imagesInternal[currentIndex];
    
                // remove from checkin instance (and splide)
                vm.imagesInternal.splice(currentIndex, 1);
                // vm.$refs.primary.splide.options.start =
                //     currentIndex == vm.imagesInternal.length
                //         ? vm.imagesInternal.length - 1
                //         : currentIndex;
                vm.imagesInternal = [...vm.imagesInternal];
    
                // let the parent know of the new image list - this will trigger a save in Sidebar
                vm.$emit("update:images", vm.imagesInternal);
    
                // delete the image from storage too
                var storageReference = storage.ref(image.storagePath);
                storageReference
                    .delete()
                    .then(() => {
                        resolve()
                    })
                    .catch((error) => {
                        reject();
                    });
            })

        },
        handleUploadStarted() {
          this.isLoadingImage = true;
        },
        handleUploaded(result) {
            // display the file
            const vm = this;
            // add this on the checkin record (will autosave)
            var storageReference = storage.ref(result.storagePath);
            storageReference
                .getDownloadURL()
                .then((url) => {
                    let newImage = {
                        storagePath: result.storagePath,
                        name: result.filename,
                        storageUrl: url,
                    };

                    
                    // we're only ever going to have one image at the moment so will always
                    // be inserting into an empty array
                    vm.imagesInternal.push(newImage);

                    // reset the images on the splide - have to set, rather than mutate
                    vm.imagesInternal = [...vm.imagesInternal];

                    vm.$emit("update:images", vm.imagesInternal);
                    vm.isLoadingImage = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
});
</script>

<style scoped>
.imageViewer.fullscreen {
    position: fixed;
    right: 0px;
    left: 0px;
    top: 0px;
    bottom: 0px;
    background: black;
}

.caption {
    height: 40px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 60px;
    position: relative;
    width: 100%;
}

.caption input {
    width: 70%;
    border: none;
    background-color: transparent;
    color: white;
    text-align: center;
}

.controlStrip {
    display: flex;
    justify-content: center;
    align-items: center;
}

.control:last-child {
    margin-left: 30px;
}
.control:first-child {
    margin-left: 0px;
}

.control {
    display: flex;
    justify-items: center;
    align-items: center;
}
.imagePlaceholder {
    background-color: var(--ish-lightgrey);
    color: var(--ish-mediumgrey);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    /* this is the padding we add to push the splide page navigation down */
    margin-bottom: 30px;
}

.imagePlaceholder div {
    cursor: pointer;
}

.image-preview {
  object-fit:cover;
  /* width:400px; */
  width:100%;
  height:300px;
}
</style>