<template>
  <div class="user-home" :class="{ 'user-home--isWorking': isWorking }">
    <header-main
      :title="pageTitle"
      :forceHide="['login', 'register']"
      :blueLogo="true"
      @logoClick="$router.push('/')"
    ></header-main>

    <div class="loader" v-if="isLoading">
        <loader></loader>
    </div>
    <div v-else class="adventures__wrapper">
      <div v-if="adventuresVM.length > 0" class="adventures" :class="{ 'adventures--single-card': adventuresVM.length == 1 }">
        <template v-for="adventure in adventuresVM" :key="adventure.id">
          <div class="adventure__card">
            <div
              class="adventure__card__body"
              @click="handleClick(adventure)"
            >
              <img
                v-if="true"
                class="adventure__card__top--hasImage"
                :src="adventure.imagePath"
              />

              <div class="adventure__card__heading">
                <span class="adventure__card__arrow material-icons"
                  >play_arrow</span
                >
                <h1 class="adventure__card__title">{{ adventure.name }}</h1>
                <div class="adventure__card__subTitle">
                  <template v-if="adventure.checkIns.length > 0">
                    <span>{{ formattedDate(adventure.startDate) }}</span>
                    <span>{{ adventure.distanceWalked + " kms" }}</span>
                    <span>{{ adventure.numberOfTrailDays + " days" }}</span>
                  </template>
                  <template v-else>
                    <span>Not started yet</span>
                  </template>
                </div>
              </div>
              <div class="card__text">
                <p class="adventure__card__text">
                  {{ adventure.description }}
                </p>
              </div>
            </div>
            <div class="adventure__card__footer">
              <icon
                :options="iconOptions"
                v-if="isOwner"
                tooltip="Edit"
                @click="$router.push(`/adventure/${adventure.id}`)"
                >edit</icon
              >
              <icon
                :options="iconOptions"
                tooltip="Share"
                @click="handleShareClick(adventure)"
                >share</icon
              >

              <subscribe-button
                v-if="!isOwner && adventure.allowSubscribe"
                :campaignId="adventure.subscriptionKey"
                >Subscribe</subscribe-button
              >

              <base-check-box
                v-if="isOwner"
                class="adventure__card__footer__publicflag"
                label="Public"
                v-model="adventure.public"
                @change="
                  updateAdventureVisibility(adventure.id, adventure.public)
                "
              ></base-check-box>
            </div>
          </div>
        </template>
        <share
          v-if="showShare"
          :adventure="shareAdventure"
          :shareLinks="shareLinks"
          @close="showShare = false"
        ></share>
      </div>
      <div v-else class="adventure__card">
        <div class="adventure__card__body">
          <div class="card__text">
            <p v-if="showInvalidUserName" class="adventure__card__text">
              No adventures yet.
            </p>
            <p v-else class="adventure__card__text">User not found</p>
          </div>
        </div>
      </div>
  
      <hr class="divider" />

      <base-button v-if="isOwner" @click="createNew">Create</base-button>

      <div class="user-home__eoi">
        <p>
          If you have any feedback, or want to try out <b>istayedhere</b> for
          your own Te Araroa thru-hike, please get in touch!
        </p>
        <a
          title="send email"
          target="_blank"
          href="mailto:andrewtokeley@gmail.com?subject=Interested in iStayedHere!"
          >andrewtokeley@gmail.com</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { UrlBuilder } from "../../api/classes/UrlBuilder";
import {
  getPublicAdventuresByUsername,
  getAdventuresByUid,
  updateAdventureVisibility,
} from "../../api/firestoreAdventures";
import { getCheckIns } from "../../api/firestoreCheckIn";
import { getUserByUsername } from "../../api/firestoreUser";
import { constants } from "../../constants";

import { CustomLink } from "../../api/classes/CustomLink";
import { ShareLinks } from "../../api/classes/ShareLinks";

import HeaderMain from "../controls/Header.vue";
import Icon from "../controls/Icon.vue";
import Share from "../controls/Share.vue";
import SubscribeButton from "../controls/SubscribeButton.vue";
import BaseButton from "../controls/BaseButton.vue";
import BaseCheckBox from "../controls/BaseCheckBox.vue";
import Loader from "../controls/Loader.vue";

import { mapState } from "vuex";
import { Adventure } from "../../api/classes/Adventure";

const { DateTime } = require("luxon");

export default defineComponent({
  name: "UserHome",
  title: "User Home",
  components: {
    HeaderMain,
    Icon,
    Share,
    BaseButton,
    BaseCheckBox,
    SubscribeButton,
    Loader,
  },

  data() {
    return {
      customLinks: Array,
      isWorking: true,
      showDeleteAlert: false,
      showShare: false,
      shareAdventure: Adventure,
      shareLinks: [],
      adventuresVM: [],
      isPublic: false,
      pageTitle: "",
      showInvalidUserName: false,
      isLoading: true,
    };
  },

  computed: {
    ...mapState({
      user: "user",
      loggedIn: "loggedIn",
    }),
    iconOptions() {
      return constants.ICON_OPTIONS.ON_WHITE_ACTIONABLE;
    },
    username() {
      return this.$route.params.username;
    },
    isOwner() {
      return this.loggedIn && this.user.username == this.username;
    },
  },

  watch: {
    "user.displayName": function () {
      this.setPageTitle();
    },
  },

  mounted() {
    // currently all adventures will subscribe to my campaign! I'm the only one who should enable subscriptions for now :-)
    // this.campaignid = "9f142f41f5";

    // let script = document.createElement('script');
    // script.setAttribute('src', `https://marvelous-designer-8310.ck.page/${this.campaignid}/index.js`);
    // script.setAttribute('async', '');
    // script.setAttribute('data-uid', this.campaignid);
    // document.head.appendChild(script);

    //document.title = "I Stayed Here" + (this.user.displayName ? " - " + this.user.displayName : "");
    this.setPageTitle();
    this.initialiseView();
    this.customLinks = [
      new CustomLink("Today's video", "http://facebook.com/video/123"),
      new CustomLink("Hotel website", "http://dfsdf"),
    ];
  },

  methods: {
    handleShareClick(adventure) {
      this.shareAdventure = adventure.adventure;
      this.shareLinks = this.getShareLinks(
        adventure.adventure,
        adventure.checkIns
      );
      this.showShare = true;
    },

    handleSubscribe() {
      this.showSubscribe = true;
    },

    getShareLinks(adventure, checkIns) {
      let shareLinks = new ShareLinks(adventure, checkIns);
      console.log('s')
      return shareLinks.asArray()
    },
    deleteCustomLink(i) {
      this.customLinks.splice(i, 1);
    },
    addCustomLink() {
      this.customLinks.push(new CustomLink());
    },
    setPageTitle() {
      this.pageTitle = "";
      if (!this.showInvalidUserName) {
        if (this.isOwner) {
          // the displayname is stored in state
          this.pageTitle = this.user.displayName;
        } else {
          // need to make sure you're displaying the displayname of the user with the current username
          let vm = this;
          getUserByUsername(this.username).then((user) => {
            vm.pageTitle = user.displayName;
          });
        }
      }
    },

    updateAdventureVisibility(id, isPublic) {
      updateAdventureVisibility(id, isPublic)
        .then(() => {
          // do nothing?
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formattedDate(date) {
      if (date) {
        return date.toLocaleString(DateTime.DATE_MED);
      } else {
        return "";
      }
    },

    createNew() {
      this.$router.push("/adventure");
    },

    adventureUrl(adventure) {
      if (this.isOwner) {
        return UrlBuilder.urlForDashboard(
          adventure.username,
          adventure.id,
          null,
          { mode: "edit", latest: true }
        );
      } else {
        return UrlBuilder.urlForDashboard(
          adventure.username,
          adventure.id,
          null,
          { mode: "view", latest: true }
        );
      }
    },

    handleClick(adventure) {
      return this.$router.push(this.adventureUrl(adventure));
    },

    createAdventureVM(adventures) {
      let vm = this;
      let index = 0;
      let results = [];

      adventures.forEach((adventure) => {
        let adventureVM = {
          // the share dialog needs the full adventure :-( should prob change
          adventure: adventure,
          username: adventure.username,
          id: adventure.id,
          name: adventure.name,
          description: adventure.description,
          uid: adventure.uid,
          startDate: null,
          public: adventure.public,
          allowSubscribe: adventure.allowSubscribe,
          subscriptionKey: adventure.subscriptionKey,
        };

        // hardcoded for now
        adventureVM.imagePath = require("/assets/tile_cape_reinga.png");
        if (adventure.trail) {
          if (adventure.trail.key == 'BB') {
            adventureVM.imagePath = require("/assets/bb-adventure-header.png");
          }
        }        

        index += 1;

        // get the checkins for this adventure too (expensive if this thing takes off!)
        getCheckIns(this.isOwner ? this.user.uid : null, adventure.id).then(
          (checkIns) => {
            adventureVM.checkIns = checkIns;
            if (checkIns.first()) {
              adventureVM.startDate = checkIns.first().date;
            }
            if (checkIns.statistics) {
              adventureVM.distanceWalked = checkIns.statistics.distanceWalked;
              adventureVM.numberOfTrailDays =
                checkIns.statistics.numberOfTrailDays ?? 0;
            }
            vm.adventuresVM.push(adventureVM);
            vm.adventuresVM.sort((a, b) => {
              if (a.startDate < b.startDate) {
                return 1;
              } else {
                return -1;
              }
            });
            vm.isLoading = false;
          }
        );
      });
    },

    initialiseView() {
      const vm = this;
      vm.isWorking = true;
      if (vm.isOwner) {
        getAdventuresByUid(vm.user.uid)
          .then((adventures) => {
            vm.createAdventureVM(adventures);
          })
          .catch((error) => {
            vm.showInvalidUserName = true;
          })
          .finally(() => {
            vm.isWorking = false;
          });
      } else {
        getPublicAdventuresByUsername(vm.username)
          .then((adventures) => {
            vm.createAdventureVM(adventures);
          })
          .catch((error) => {
            vm.showInvalidUserName = true;
          })
          .finally(() => {
            vm.isWorking = false;
          });
      }
    },
  },
});
</script>

<style scoped>
.user-home {
  position: absolute;
  background: white;
  bottom: 0px;
  top: 0px;
  width: 100%;
  overflow-y: scroll;
  cursor: default;
}

.user-home--isWorking {
  /* cursor: progress; */
  display: none;
}

.adventures__wrapper {
  position: absolute;
  top: 70px;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}

.adventures {
  width: 100%;
  max-width: 850px;
  background: transparent;
  /* padding-top: 20px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.adventures--single-card {
  justify-content: center;
}

.adventure__card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* min-width: 350px; */
  width: 45%;
  border: 1px solid var(--ish-lightgrey);
  border-radius: 5px;
  margin: 20px;
  overflow-y: hidden;
}

.loader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 400px;
  width:100%;
}

.adventure__card__body {
  cursor: pointer;
  height: 400px;
  width: 100%;
}

.adventure__card__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  box-sizing: border-box;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border-top: 1px solid var(--ish-lightgrey);
  padding-top: 10px;
  padding-bottom: 5px;
}

.adventure__card__footer__publicflag {
  width: 180px;
}
.adventure__card:hover {
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
}

.adventure__card__top {
  position: relative;
  width: 100%;
  height: 90px;
  background: var(--ish-blue);
}

.adventure__card__top--hasImage {
  position: relative;
  width: 100%;
  height: 80px;
  object-fit: cover;
}

.adventure__card__heading {
  position: relative;
  margin-bottom: 40px;
  margin-top: -71px;
  /* background: rgba(0, 0, 0, 0.6); */
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
}

.adventure__card__arrow {
  position: absolute;
  top: 0px;
  right: 10px;
  color: white;
}

.adventure__card__title {
  position: relative;
  color: white;
  margin: 0px;
  margin-top: 5px;
  margin-right: 40px;
  font-size: var(--ish-font-size-large);
  overflow: hidden;
  white-space: nowrap;
}

.adventure__card__subTitle {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: var(--ish-font-size-small);
  font-weight: 100;
  color: white;
  white-space: pre;
  margin-bottom: 5px;
}

.card__text {
  padding-left: 10px;
  padding-right: 10px;
}

.adventure__card__date {
  font-size: var(--ish-font-size-small);
  color: var(--ish-darkgrey);
}

.adventure__card__text {
  font-size: var(--ish-font-size-normal);
  color: var(--ish-mediumgrey);
  white-space: pre-line;
  white-space: pre-wrap;
  height: 220px;
  overflow: scroll;
}

.user-home__eoi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user-home__eoi p {
  width: 95%;
  max-width: 450px;
  text-align: center;
  color: var(--ish-mediumgrey);
}

.user-home__eoi_button {
  width: 250px;
}

@media screen and (max-width: 650px) {
  /* .adventures {
    flex-direction: column;
  } */
  .adventure__card {
    width: 90%;
  }

  .user-home__eoi {
    width: 80%;
  }
}
</style>