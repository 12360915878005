<template>
  <div>
    <div class="header">
      <router-link to="/">
        <logo class="header__logo"></logo>
      </router-link>
      <div v-show="!isMobile()" class="header__title">{{ title }}</div>
      <div class="header__buttons">
        <a class="header__navlink" v-if="showAbout" @click="showAboutModal = true">About</a>
        <router-link class="header__navlink" v-if="showFollowTokes" to="tokes"
          >Follow Tokes</router-link
        >
        <router-link class="header__navlink" v-if="showLogin" to="login"
          >Log In</router-link
        >
        <router-link class="header__navlink" v-if="showRegister" to="signup"
          >Register</router-link
        >
        <router-link
          class="header__navlink"
          v-if="showDashboard"
          :to="dashboardUrl"
          >Dashboard</router-link
        >

        <icon
          class="header__menu-icon"
          v-if="loggedIn"
          :options="accountIconOptions"
          @menuClick="handleMenuClick"
          >menu</icon
        >
      </div>
    </div>
    <user-settings v-if="showSettings" @close="handleClose"></user-settings>
    <follow v-if="showFollow" @close="showFollow = false"></follow>
    
    <about 
      v-if="showAboutModal"
      @close="showAboutModal = false">
    </about>
  </div>
</template>

<script>
import { auth } from "../../api/firebaseInit";
import { mapState } from "vuex";
import { constants } from "../../constants";

import Logo from "./Logo";
import Icon from "./Icon.vue";
import Follow from "./Follow.vue";
import UserSettings from "./UserSettings.vue";

import { UrlBuilder } from "../../api/classes/UrlBuilder";
import About from '../pages/About.vue';

export default {
  name: "Header",

  emits: ["logoClick"],

  components: {
    Logo,
    Icon,
    UserSettings,
    Follow,
    About,
  },

  props: {
    // array of buttons to forceHide
    forceHide: {
      type: Array,
      default: new Array(),
    },
    blueLogo: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      showSettings: false,
      showFollow: false,
      showAboutModal: false,
      testMessage:
        process.env.VUE_APP_VERSION + ", " + process.env.VUE_APP_FB_AUTH_DOMAIN,
    };
  },

  computed: {
    ...mapState({
      user: "user",
      loggedIn: "loggedIn",
    }),
    hasFollows() {
      return this.user.following && this.user.following.length > 0;
    },
    accountIconOptions() {
      let options = constants.ICON_OPTIONS.ON_DARK;
      options.menu = {
        position: true,
        menuItems: [
          { id: 3, name: "Home", iconName: "home" },
          {
            id: 5,
            name: "Admin",
            show: this.user.isAdmin ?? false,
            iconName: "admin",
          },
          { isDivider: true },

          /* Friends (options) */
          {
            id: 100,
            name: "Friends",
            iconName: "group",
            isLabel: true,
            show: this.hasFollows,
          },
          { id: 4, name: "Search..." },
          { isDivider: true, show: this.hasFollows },

          { id: 0, name: "Settings...", iconName: "settings" },
          { isDivider: true },
          { id: 1, name: "Logout", subText: this.user.email },
          { isDivider: true },
          {
            subText: process.env.VUE_APP_VERSION,
            isFullWidth: true,
            isLabel: true,
          },
        ],
      };

      let index = options.menu.menuItems.findIndex((m) => m.id == 100);
      let offSet = 1;
      if (index >= 0) {
        if (this.user.following) {
          this.user.following.forEach((f) => {
            let menuItem = { id: 6, tag: f.username, name: f.displayName };
            if (this.$route.params.username == f.username) {
              menuItem.iconName = "check";
            }
            options.menu.menuItems.splice(index + offSet, 0, menuItem);
            offSet += 1;
          });
        }
      }

      return options;
    },
    showLogin() {
      if (this.$route.path == "/login") return false;

      return !this.hideButtons && !this.hide("login") && !this.loggedIn;
    },
    showLogout() {
      return !this.hideButtons && !this.hide("login") && this.loggedIn;
    },
    showRegister() {
      if (this.$route.path == "/login") return false;
      if (this.$route.path == "/signup") return false;
      if (this.$route.path == "/signup2") return false;
      return !this.hideButtons && !this.hide("register") && !this.loggedIn;
    },
    showDashboard() {
      if (this.$route.path != "/") return false;
      return !this.hideButtons && !this.hide("login") && this.loggedIn;
    },

    showAbout() {
      return !this.loggedIn;
    },

    showFollowTokes() {
      //return !this.loggedIn && this.$route.path == "/";
      return false;
    },

    hideButtons() {
      return false;
    },

    dashboardUrl() {
      const username = this.user.username;
      const url = username ? `${username}` : `signup2`;
      return url;
    },
  },

  methods: {
    handleDisplayNameChanged(newName) {
      this.displayTitle = newName;
    },
    handleClose() {
      // refresh the heading in case it changed
      this.showSettings = false;
    },
    isMobile() {
      return screen.width <= 760;
    },
    hide(name) {
      return this.forceHide.includes(name);
    },
    handleLogout() {
      auth
        .signOut()
        .then(() => {
          this.$router.push("/");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openSettings() {
      this.showSettings = true;
    },
    handleMenuClick(menuItem) {
      let id = menuItem.id;
      if (id == 0) {
        this.openSettings();
      } else if (id == 1) {
        this.handleLogout();
      } else if (id == 5) {
        this.$router.push("/admin");
      } else if (id == 4) {
        this.showFollow = true;
      } else if (id == 3) {
        this.$router.push(UrlBuilder.urlForUserHome());
      } else if (id == 6) {
        this.$router.push(UrlBuilder.urlForUserHome(menuItem.tag));
      }
    },
  },
};
</script>

<style scoped>
.header {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  top: 0px;
  left: 0px;
  right: 0px;
  background: var(--ish-blue);
}

.header__logo {
  width: 100px;
}

.header__title {
  font-size: var(--ish-font-size-large);
  color: white;
}

.header__buttons {
  float: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
  min-width: 100px;
}

.header__navlink {
  cursor: pointer;
  color: white;
  text-decoration: none;
  transition: color 0.2s ease;
  padding-left: 20px;
  padding-right: 15px;
}

.header__navlink:hover {
  color: var(--ish-lightblue);
}

.header__menu-icon {
  padding-left: 20px;
}

@media screen and (max-width: 650px) {
  .header__logo {
    width: 20%;
  }

  .header__title {
    display: none;
  }
}
</style>
