<template>
  <div  class="image-preview" 
        @click='preventClickPropogation'>
    <icon :options="closeIconOptions" class="image-preview__close-button" @click='$emit("close")'>close</icon>
    
    <img ref="image" class="content__image" :src="src" />
    
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { constants } from "../../../constants";
import { mapMutations } from 'vuex'
import Icon from "../../controls/Icon.vue";
import 'viewerjs/dist/viewer.css';
import Viewer from "viewerjs";

export default defineComponent({
  name: "image-preview",

  emits: ["close"],
  
  components: {
    Icon,
  },

  props: {
    src: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    closeIconOptions() {
      const options = {...constants.ICON_OPTIONS.ON_WHITE_ACTIONABLE};
      options.size = "32px";
      options.background.size = "50px";
      return options;
    },
  },

  mounted() {
    this.addShortcutListener(
      {
        name: this.src, 
        callback: this.handleEscape,
        listenTo: ['esc','Escape',27],
      }
    );

    const image = this.$refs.image;
    const viewer = new Viewer(image, {
      inline: false,
        viewed() {
          viewer.zoomTo(1);
        },
});
    viewer.show();
  },

  unmounted() {
    this.removeShortcutListener(this.src);
  },

  methods: {
    handleEscape() {
      this.$emit('close');
    },

    ...mapMutations({
      addShortcutListener: 'ADD_SHORTCUT_LISTENER',
      removeShortcutListener: 'REMOVE_SHORTCUT_LISTENER',
    }),

    preventClickPropogation(e) {
      e.stopPropagation();
    },

  },


});
</script>

<style scoped>
.image-preview {
  z-index: 1000;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding: 20px 20px;
  box-sizing: border-box;
}

.image-preview__close-button {
  position:absolute;
  right: 10px;
  top: 10px;
}

.content__image {
  /* object-fit: cover; */
  max-width:95%;
  max-height:95%;
}
/* @media only screen and (max-width: 600px) {
  .modal {
    max-width: 95%;
  }
} */
</style>