import "../src/main.css"
// import "@splidejs/splide/dist/css/themes/splide-default.min.css"
import { createApp } from "vue"
import { createRouter, createWebHistory } from "vue-router"
import routes from "./routes"
import App from "./components/App.vue"
import store from "./store.js";
import { auth } from "./api/firebaseInit";
import { analytics } from './api/firebaseInit'
import { recordUserLogin } from "./api/firestoreUser"
import $bus from './event.js'

const { DateTime } = require("luxon");
//import VueMeta from 'vue-3-meta'

/**
 * This mixin will create a page_view google analytics event for any component that sets the trackAsPageView property on the component instance.
 * @example
 * export default defineComponent({
 *  trackAsPageView: true,
 *  trackWithPageName: "Map Info",
 *  ...
 * })
 */
var analyticsMixin = {
  created: function () {
    if (this.$options.trackAsPageView) {
      analytics.logEvent('page_view', {
        page_location: this.$route.fullPath,
        page_path: this.$route.path,
        page_title: this.$options.trackWithPageName ?? "Unknown",
      });
    }
  }
}

const titleMixin = {
  created() {
    const title = this.getTitle(this)
    if (title) {
      document.title = title
    }
  },
  methods: {
    getTitle(vm) {
      const { title } = vm.$options
      if (title) {
        let titleValue = typeof title === 'function'
          ? title.call(vm)
          : title
        return `iStayedHere - ${titleValue}`;
      } 
    }
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

// add guard to auth pages

router.beforeEach((to, from) => {
  analytics.logEvent('page_view', {
    page_location: to.fullPath,
    page_path: to.path,
    page_title: to.meta.page_title
  });
  if (to.meta.requiresAuth) {
     if ((!store.state.loggedIn) || (to.meta.requiresAdmin && !store.state.user.isAdmin)) {
        // redirect to home
        return "/";
     }
  } else {
    // continue to the url
    return true;
  }
})

let app;
auth.onAuthStateChanged((user) => { 
  store.dispatch("updateAuthUser", user).then( () => {
    // if this is the first auth result and we haven't yet created the app...
    if (!app) {
      
      if (user) {
        recordUserLogin(user.uid, DateTime.local());
      }

      // now that we know who (if anyone) is logged in, we can load and each component
      // will be able to rely on store for auth state.

      app = createApp(App)

      app.config.globalProperties.$bus = $bus;
      app.config.globalProperties.$isMobile = (screen.width <= 760);
      app.mixin(titleMixin);
      app.mixin(analyticsMixin);
      app.use(router);
      app.use(store);
      //app.use(VueMeta);
      app.mount('#app')
    }
    
  });  
});
