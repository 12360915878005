<template>
  <div class="sbdisplay">
    <div class="sbdisplay__notes">{{ checkIn.notes }}</div>

    <hr v-if="checkIn.notes" class="divider" />
    <div
      class="sbdisplay__image-group"
      v-show="image"
    >
      <img
        ref="checkInImage"
        :alt="imageCaption"
        v-show="showImage"
        class="sbdisplay__image"
        :src="image"
      />
      <loader v-show="!showImage" :size="40" :borderSize="4" ></loader>
    </div>
    <div class="sbdisplay__image--caption" v-show="image">
      {{ imageCaption }}
    </div>

    <!-- <modal-dialog
      v-show="showImageFullScreen"
      :subTitle="imageCaption"
      :fullscreen="true"
      @close="showImageFullScreen = false"
    >
      <img ref="imagePreview" v-if="image" class="sbdisplay__modal__image" :src="image" />
    </modal-dialog> -->

    <hr v-if="image" class="divider" />

    <div
      v-if="checkIn.customLinks && checkIn.customLinks.length > 0"
      class="sbdisplay__bullet"
    >
      <icon class="sbdisplay__bullet__icon">link</icon>
      <div class="sbdisplay__bullet__links">
        <template
          v-for="customLink in checkIn.customLinks"
          :key="customLink.url"
        >
          <a v-if="customLink.url" target="blank" :href="customLink.url">{{
            customLink.title ?? customLink.url
          }}</a>
        </template>
      </div>
    </div>

    <div>
      <div class="sbdisplay__bullet">
        <icon class="sbdisplay__bullet__icon">place</icon>
        <div class="sbdisplay__bullet__text">
          {{ checkIn.address }}
          <div class="sbdisplay__bullet__text--small">
            Nearest to km {{ checkIn.nearestTrailMarker }}
          </div>
        </div>
      </div>

      <div class="sbdisplay__bullet" v-if="checkIn.numberOfRestDays > 0">
        <icon class="sbdisplay__bullet__icon">hourglass_bottom</icon>
        <div class="sbdisplay__bullet__text">{{ restDaysMessage }}</div>
      </div>

      <div class="sbdisplay__bullet" v-if="checkIn.numberOfOffTrailDays > 0">
        <icon class="sbdisplay__bullet__icon">hourglass_bottom</icon>
        <div class="sbdisplay__bullet__text">{{ offTrailDaysMessage }}</div>
      </div>

      <div class="sbdisplay__bullet" v-if="checkIn.resupply > 0">
        <icon class="sbdisplay__bullet__icon">shopping_cart</icon>
        <div class="sbdisplay__bullet__text">{{ checkIn.resupplyNotes }}</div>
      </div>
    </div>

    <div ref="bottomSwipePanel" class="bottomSwipePanel"></div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { CheckIn } from "../../api/classes/CheckIn";
import ModalDialog from "../controls/ModalDialog.vue";
import Loader from "../controls/Loader.vue";
import Icon from "../controls/Icon";
import { constants } from "../../constants";

import Viewer from "viewerjs";
import 'viewerjs/dist/viewer.css';

var Hammer = require("hammerjs");

export default defineComponent({
  components: {
    Loader,
    Icon,
  },
  name: "SidebarCheckInDisplay",

  emits: ["swipe"],

  props: {
    checkIn: CheckIn,
  },

  data() {
    return {
      showImageFullScreen: false,
      showImage: false,
    };
  },

  computed: {
    accomodationIconOptions() {
      let options = { ...constants.ICON_OPTIONS.ON_WHITE };
      options.isClickable = false;
      return options;
    },
    image() {
      if (this.checkIn.images) {
        if (this.checkIn.images[0]) {
          return this.checkIn.images[0].storageUrl;
        }
      }
      return null;
    },
    imageCaption() {
      if (this.checkIn.images) {
        if (this.checkIn.images[0]) {
          return this.checkIn.images[0].caption;
        }
      }
      return null;
    },
    offTrailDaysMessage() {
      return `${this.checkIn.numberOfOffTrailDays} ${
        this.checkIn.numberOfOffTrailDays > 1 ? "days" : "day"
      } off trail`;
    },
    restDaysMessage() {
      return `${this.checkIn.numberOfRestDays} rest ${
        this.checkIn.numberOfRestDays > 1 ? "days" : "day"
      }`;
    },
  },

  mounted() {
    this.addListeners();

    const image = this.$refs.checkInImage;
    const viewer = new Viewer(image, {
      inline: false,
      navbar:0,
      title: [1, (image, imageData) => `${image.alt}`],
      toolbar:0,
    });
  },

  methods: {
    
    addListeners() {
      let vm = this;

      if (Hammer) {
        const vm = this;
        var mc = new Hammer(this.$refs.bottomSwipePanel);
        mc.get("swipe").set({ direction: Hammer.DIRECTION_VERTICAL });
        mc.on("swipeup swipedown", function (ev) {
          vm.$emit("swipe", ev.type);
        });
      }

      this.$bus.$on("didNavigateGlobal", (data) => {
        // hide until the image loads
        vm.showImage = false;
      });
      const img = this.$refs.checkInImage;
      img.addEventListener("load", () => {
        vm.showImage = true;
        //console.log('load');
      });
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
 <style>
.viewer-backdrop {
    background-color: rgba(255, 255, 255);
}
.viewer-title {
  color: var(--ish-darkgrey);
  background-color: white;
  padding: 5px;
  margin-bottom: 20px;
}
</style>

<style scoped>

.sbdisplay {
  padding: 0px 20px 20px 20px;
}

/* .sbdisplay__stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.sbdisplay__stats--light {
  color: var(--ish-mediumgrey);
}

.sbdisplay__stats--highlight {
  color: var(--ish-darkgrey);
  font-size: 1.2em;
  font-weight: bold;
}

.sbdisplay__acc {
  display:flex;
  flex-direction: column;
  align-items: center;
}
 
.sbdisplay__acc--text {
  font-size: var(--ish-font-size-small);
  color: var(--ish-mediumgrey);
  text-transform: lowercase;
} */

.sbdisplay__image-group {
  display: flex;
  background-color: var(--ish-lightgrey);
  /* background-image: url('~@/../assets/placeholder-image.png');
  background-size: 100% 340px; */
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.sbdisplay__image-group,
.sbdisplay__image {
  max-width: 100%;
  height: 340px;
}

.sbdisplay__image {
  object-fit: cover;
}

.sbdisplay__image--caption {
  /* position: relative; */
  /* top: -50px; */
  /* background: rgba(0, 0, 0, 0.5); */
  color: var(--ish-darkgrey);
  /* height: 30px; */
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  /* background-color: white; */
}

.sbdisplay__modal__image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.sbdisplay__notes {
  margin-top: 20px;
  margin-bottom: 20px;
  white-space: pre-line;
  white-space: pre-wrap;
}

.sbdisplay__bullet {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
}

.sbdisplay__bullet:first-child {
  margin-top: 20px;
}

.sbdisplay__bullet__icon {
  margin-left: -10px;
  margin-right: 10px;
}

.sbdisplay__bullet__title {
  width: 80%;
}

.sbdisplay__bullet__text {
  white-space: pre-wrap;
}

.sbdisplay__bullet__text--small {
  margin-top: 10px;
  margin-left: -3px;
  font-size: var(--ish-font-size-small);
}

.sbdisplay__bullet__links a {
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
}

.bottomSwipePanel {
  background: transparent;
  height: 100px;
  width: 100%;
  bottom: 0px;
  left: 0px;
  position: absolute;
}
</style>
