<template>
  <div class="footer">
    <base-button
      class="footer__button"
      title="Tokes' Thru-Hikes'"
      target="_blank"
      href="/tokes"
      >Tokes' Thru-Hikes</base-button
    >
    <!-- <p class="footer__text">(c) Andrew Tokeley, 2021</p> -->
  </div>
</template>

<script>
import BaseButton from "./BaseButton.vue";

export default {
  components: { BaseButton },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0px;
  width: 100%;

  padding-right: 10px;
  padding-left: 10px;
  box-sizing: border-box;
  background: rgba(1, 1, 1, 0.3);
  color: gray;
}

.footer__button {
  margin-top: 10px;
  margin-bottom: 20px;
}
.footer__text {
  color: white;
  font-size: var(--ish-font-size-small);
}
</style>
