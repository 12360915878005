<template>
  <modal-dialog title="About" @close="$emit('close')">
    <div>
      <p>
        A hopefully growing collection of thru-hikes, sharing where I stayed, how far I walked, 
        where I resupplied and a note/photo to mark each day :)
      </p>
      <p class="version">Version {{ appVersion }}</p>
    </div>
  </modal-dialog>
</template>

<script>
import { defineComponent } from "vue";
import ModalDialog from "../controls/ModalDialog.vue";

export default defineComponent({
  name: "about",
  trackAsPageView: true,
  trackWithPageName: "About",
  emits: ["close"],
  components: {
    ModalDialog,
  },
  data() {
    return {
      appVersion: "",
    }
  },
  mounted() {
    this.appVersion = process.env.VUE_APP_VERSION;
  }
});
</script>

<style scoped>

.version {
  font-size: var(--ish-font-size-small);
}
</style>  