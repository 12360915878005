<template>
  <div class="page" :class="{ isWorking: isWorking }">
    <header-main
      :hideButtons="true"
      @logoClick="$router.push('/')"
    ></header-main>

    <div class="content">
      <div class="wrapper">
        <h1 v-if="$route.params.adventureid">{{ title }}</h1>
        <h1 v-else>Create a New Adventure</h1>
        <p>
          The information you provide here will be visible to people you share
          your adventure with.
        </p>
        <div class="grid">
          <icon class="icon" :options="iconOptions">hiking</icon>
          <div class="entry">
            <!-- <div style="padding-top: 20px; padding-left: 10px">Te Araroa</div>
            <div class="helpText">
              Currently only Te Araroa thru hike is supported.
            </div> -->
            <base-select
              :options="{
                includeBlankOption: false,
              }"
              v-model="adventure.trail"
              :selectOptions="trailArray"
            ></base-select>
          </div>
          
          <div class="entry">
            <base-input
              v-model="adventure.name"
              :options="{ 
                maximumLength: 30,
                placeholder: 'Add name'}"
            ></base-input>
          </div>
          
          <div class="entry">
            <base-multiline-text
              v-model="adventure.description"
              :options="{ 
                placeholder: 'Add description',
                numberOfLines: 10,
                maximumLength: 1000, 
                showCharacterCount: true}"
            ></base-multiline-text>
          </div>

          <!-- <div class="entry">
            <base-check-box
              label="Allow people to subscribe to updates"
              :leftAlign="true"
              v-model="adventure.allowSubscribe">
            </base-check-box>
          </div> -->
          <!-- <div class="entry">
            <base-input
              :options="{ 
                placeholder: 'Proposed start marker',
                descriptionText: 'Where you intend to start your adventure (Optional) ' }"
            ></base-input>
          </div>

          <div class="entry">
            <base-input
              :options="{ placeholder: 'Proposed end marker',
              descriptionText: 'Where you intend to end your adventure (Optional) ' }"
            ></base-input>
            
          </div> -->
          
        </div>
        <div class="buttonStrip">
          <div class="buttonStrip--left">
            <base-button
              :isDestructive="true"
              @click="handleDeleteOpen"
              >Delete</base-button
          >
          </div>
          <div class="buttonStrip--right">
            <base-button
              :isSecondary="true"
              @click="$router.push(`/${$store.state.user.username}`)"
              >Cancel</base-button
            >
            <base-button @click="handleSave">Save</base-button
            >
          </div>
        </div>
      </div>
    </div>

    <modal-dialog v-if="showDeleteAlert" title="Delete" :actions="deleteActions" @close="showDeleteAlert = false;">
      <p>Are you sure?</p>
      <p>This will delete all checkins, images...</p>  
    </modal-dialog>

  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";

import HeaderMain from "../controls/Header";
import BaseInput from "../controls/BaseInput.vue";
import BaseSelect from "../controls/BaseSelect.vue";
import BaseButton from "../controls/BaseButton.vue";
import Icon from "../controls/Icon.vue";
import BaseMultilineText from "../controls/BaseMultilineText.vue";
import ModalDialog from "../controls/ModalDialog";

import {
  addAdventure,
  getAdventure,
  updateAdventure,
} from "../../api/firestoreAdventures";
import { Adventure } from "../../api/classes/Adventure";

import { constants } from "../../constants";

const { DateTime } = require("luxon");

export default defineComponent({
  name: "UserHome",
  title: "Adventure",
  components: {
    HeaderMain,
    BaseInput,
    BaseButton,
    BaseSelect,
    Icon,
    BaseMultilineText,
    ModalDialog,
  },

  data() {
    const vm = this;
    return {
      showDeleteAlert: false,
      trailArray: [],
      isWorking: true,
      adventure: {
        type: Adventure,
        default: new Adventure({}),
      },
      title: " ",
      deleteActions: [
        { id: 0, 
          title: "Cancel", 
          isSecondary: true,
          handle: () => {
            vm.showDeleteAlert = false;
          }
        },
        { id: 1, 
          title: "Delete", 
          isDestructive: true,
          handle: () => {
            vm.handleDelete();
            vm.showDeleteAlert = false;
          }
        }
      ],
    };
  },

  computed: {
    ...mapState({
      user: "user",
      loggedIn: "loggedIn",
    }),
    iconOptions() {
      return constants.ICON_OPTIONS.ON_WHITE;
    },
  },

  mounted() {
    const id = this.$route.params.adventureid;
    if (id) {
      this.retrieveAdventure(id);
    } else {
      this.title = "Create a new adventure";
    }
    this.trailArray = [{key: 'TA', value: 'Te Araroa'},
                       {key: 'BB', value: 'Bibbulmun'}];
  },

  methods: {
    retrieveAdventure(id) {
      const vm = this;
      getAdventure(id).then((adventure) => {
        if (adventure) {
          console.log(adventure);
          vm.adventure = adventure;
          vm.title = "Update details about your adventure";
        } else {
          vm.title = "Error!";
        }
      });
    },

    handleDeleteOpen() {
      this.showDeleteAlert = true;
    },

    handleDelete() {
      console.log('do delete'); 
    },

    handleSave() {
      this.isWorking = true;
      const vm = this;

      if (this.adventure.id) {
        // this is an existing adventure, update...
        updateAdventure(this.adventure).then( () => {
          vm.$router.push(`/${this.$store.state.user.username}`);
        }).catch( (error) => {
          console.log(error);
        });
        
      } else {
        // this is a new adventure, associate with current user
        this.adventure.uid = this.user.uid;
        this.adventure.username = this.user.username;
        addAdventure(this.adventure).then(() => {
          this.isWorking = false;
          vm.$router.push(`/${this.user.username}`);
        }).catch( (error) => {
          console.log(error);
        }
        );
      }
    },
  },
});
</script>

<style scoped>
.page .working {
  cursor: progress;
}

.content {
  position: relative;
  top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
}

.wrapper {
  border-radius: 10px;
  max-width: 600px;
  background: white;
  width: 80%;
  padding: 20px;
}

.wrapper h1 {
  font-size: 1.5em;
}

.wrapper p {
  color: var(--ish-mediumgrey);
  margin-bottom: 20px;
}

.wrapper .buttonStrip {
  display: flex;
  justify-content:space-between;
}

.wrapper .buttonStrip-left {
  display: flex;
  justify-content:flex-start;
}

.wrapper .buttonStrip-right {
  display: flex;
  justify-content:flex-end;
}

.grid {
  display: grid;
  grid-template-columns: 30px 1fr;
  margin-bottom: 20px;
}

.grid .icon {
  padding-top: 10px;
  grid-column: 1 / 2;
  align-self: flex-start;
}

.grid .entry {
  grid-column: 2 / 3;
  align-self: flex-start;
}

.grid .entry .helpText {
  font-size: 0.8em;
  color: var(--ish-mediumgrey);
  padding-left: 10px;
  padding-bottom: 20px;
}
</style>