export { getLayers };

import { COLLECTIONS } from "./firestoreConstants.js";
import { db } from "./firebaseInit.js";
import { mapLayerConverter } from "./classes/MapLayer";
import { storage } from "./firebaseInit";

/**
 * Get the latest set of layers for the given trail
 * @param {String} trailId - at the moment we only have 'TA' layers
 * @param {String} type - either 'TRAIL' or 'DISTANCE'
 * @param {Number} version - if supplied returns this version of layers, otherwise the latest (by highest version number) are returned.
 */
const getLayers = async function (trailId, type, version) {
  console.log(`trailId:${trailId}`);
  
  let useVersion = version;
    if (!useVersion) {
      // if no version submitted, find the latest
      await db.collection(COLLECTIONS.LAYERS)
        .where("trail", "==", trailId)
        .orderBy("version", "desc")
        .limit(1)
        .get()
        .then(function (querySnapshot) {
          if (querySnapshot.size == 1) {
            useVersion = querySnapshot.docs[0].data().version;
          } else {
            // ideally this never happens
            useVersion = 1;
          }
          
        })
      console.log(`version:${useVersion}`);
    }
    
    return new Promise(function (resolve, reject) {
    // TODO - ideally should get the latest version if none supplied
    
    db.collection(COLLECTIONS.LAYERS)
      .where("trail", "==", trailId)
      .where("type", "==", type)
      .where("version", "==", useVersion)
      .withConverter(mapLayerConverter)
      .get()
      .then(function (querySnapshot) {
        const results = [];
        let getDownloadUrlPromises = [];
        querySnapshot.forEach(function (doc) {
          let layer = doc.data();
          results.push(layer);
          
          // store up some promises to get all the urls corresponding to the storagePaths
          getDownloadUrlPromises.push( 
            new Promise( (resolve, reject) => {
              var storageReference = storage.ref(layer.storagePath);
              storageReference.getDownloadURL()
                .then( (url) => {
                  resolve(url);
                })
                .catch((error) => {
                  reject(error);
                })
            }));
        });

        // Get all the urls
        Promise.all(getDownloadUrlPromises)
          .then ( (urls) => {
            // add the url data to the MapLayer instances, the order will be the same as the returned url order
            for (var i=0; i<urls.length; i++) {
              results[i].url = urls[i];
            }

            resolve(results);
          })
          .catch ( (error) => {
            reject(error);
          }) 
        
      }).catch(function (error) {
        reject(error);
      })
  });
}

/**
 * Get the distance marker kml
 * @param {String} trailId - must be TA for now
 */
// const getDistanceMarkersLayer = function (trailId) {
//   return new Promise(function (resolve, reject) {
//     // TODO - need to only return the most recent by map-layer.date
//     db.collection(COLLECTIONS.LAYERS)
//       .where("trail", "==", trailId)
//       .where("type", "==", "DISTANCE")
//       .withConverter(mapLayerConverter)
//       .get()
//       .then(function (querySnapshot) {
//         const result = [];
//         querySnapshot.forEach(function (doc) {
//           result.push({
//             id: doc.id,
//             name: doc.data().name,
//             url: doc.data().url,
//             storagePath: doc.data().storagePath,
//           });
//         });
//         // for now just returning the first (and only results)
//         resolve(result[0]);
//       }).catch(function (error) {
//         reject(error);
//       })
//   });
// }





