<template>
  <div>
    <div class="form gridContainer">
      <label>
        <icon :options="iconOptions">place</icon>
      </label>
      <div class="entry">
        <base-input
          v-model="checkIn.title"
          :options="{ placeholder: 'Name of where you stayed' }"
        ></base-input>
      </div>
      <div class="entry">
        <base-multiline-text
          v-model="checkIn.address"
          :options="{
            numberOfLines: 4,
            placeholder: 'Add an address',
          }"
        >
        </base-multiline-text>
      </div>
      <label v-if="!isStart">
        <icon :options="iconOptions">nordic_walking</icon>
      </label>
      <div class="entry" v-if="!isStart">
        <number-input
          v-model="checkIn.distanceWalked"
          inlineLabel="km walked"
        ></number-input>
      </div>
      <div class="entry">
        <number-input
          v-model="checkIn.nearestTrailMarker"
          :smallText="true"
          :hideButtons="true"
          descriptionText="Nearest trail marker"
        ></number-input>
      </div>
      <label v-if="!isStart">
        <icon :options="iconOptions">airline_seat_individual_suite</icon>
      </label>
      <div class="entry" v-if="!isStart">
        <base-select
          :options="{
            placeholder: 'Where did you sleep?',
            includeBlankOption: true,
          }"
          v-model="checkIn.accommodation"
          :nullOption="nullAccommodationInstance"
          :selectOptions="accommodationLookups"
        ></base-select>
      </div>

      <label>
        <icon :options="iconOptions">today</icon>
      </label>

      <div class="entry">
        <date-picker v-model="checkIn.date"></date-picker>
      </div>

      <label>
        <icon :options="iconOptions">notes</icon>
      </label>
      <div class="entry">
        <base-multiline-text
          v-model="checkIn.notes"
          :options="{
            numberOfLines: 7,
            maximumLength: 2000,
            showCharacterCount: true,
            placeholder: 'Add some notes',
          }"
        >
        </base-multiline-text>
      </div>

      <label>
        <icon :options="iconOptions">link</icon>
      </label>
      <div class="entry">
        <template
          v-for="(customLink, i) in checkIn.customLinks"
          :key="customLink.url"
        >
          <custom-link-input
            v-model="checkIn.customLinks[i]"
            @delete="deleteCustomLink(i)"
          ></custom-link-input>
        </template>
        <base-button class="btn-addcustomlink" @click="addCustomLink"
          >New Custom Link</base-button
        >
      </div>

      <div class="entry"><hr class="divider" /></div>

      <div class="entry" >
          <base-check-box v-model="checkIn.resupply" :leftAlign="true" label="Did you resupply here?" ></base-check-box>
      </div>
      <label >
        <icon :options="iconOptions">local_grocery_store</icon>
      </label>
      <div class="entry">
        <base-multiline-text
          v-model="checkIn.resupplyNotes"
          :disabled="!checkIn.resupply"
          :options="{
            numberOfLines: 7,
            maximumLength: 2000,
            showCharacterCount: true,
            placeholder: 'Add some notes about the resupply',
          }"
        >
        </base-multiline-text>
      </div>

      <div v-if="!isStart" class="entry"><hr class="divider" /></div>
      <!-- <label v-if="!isStart">
        <icon :options="iconOptions">nordic_walking</icon>
      </label>
      <div class="entry" v-if="!isStart">
        <number-input
          v-model="checkIn.distanceWalked"
          inlineLabel="km walked"
        ></number-input>
      </div> -->

      <div class="entry" v-if="!isStart">
        <number-input
          v-model="checkIn.numberOfRestDays"
          :smallText="true"
          inlineLabel="zero days"
          descriptionText="Did you take a rest day here? Zero days count towards the number of days on trail."
        ></number-input>
        
      </div>
      <div class="entry" v-if="!isStart">
        <number-input
          v-model="checkIn.numberOfOffTrailDays"
          :smallText="true"
          inlineLabel="off trail days"
          descriptionText="Typically an off day is when you pause your thru-hike to do something else. Off trail days don't affect the total number of days to complete the trail."
        ></number-input>
      </div>
    </div>
    <div class="entry"><hr class="divider" /></div>

    <image-viewer
      v-model:images="checkIn.images"
      :adventureId="adventure.id"
      :checkInId="checkIn.id"
    ></image-viewer>
    <!-- <img v-if="image" ref="image" :src="image.storageUrl" :alt="image.name" /> -->

    <div v-if="!readOnly" class="entry">
      <hr class="divider" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

// Components
// import DatePicker from "../controls/DatePicker";
// import TextInput from "../controls/TextInput";
import NumberInput from "../controls/NumberInput";
import Icon from "../controls/Icon";
import ImageViewer from "../controls/ImageViewer";

// API
//import { deleteCheckIn } from "../../api/firestoreCheckIn.js";
import { constants } from "../../constants";
import { getAccommodationLookup } from "../../api/firestoreLookup";

// Classes
import { CheckIn } from "../../api/classes/CheckIn";
import { Adventure } from "../../api/classes/Adventure";
import { CustomLink } from "../../api/classes/CustomLink";
import { Accommodation } from "../../api/classes/Accommodation";

import BaseInput from "../controls/BaseInput.vue";
import BaseMultilineText from "../controls/BaseMultilineText";
import DatePicker from "../controls/DatePicker.vue";
import BaseSelect from "../controls/BaseSelect.vue";
import CustomLinkInput from "../controls/CustomLinkInput.vue";
import BaseButton from "../controls/BaseButton.vue";
import BaseCheckBox from "../controls/BaseCheckBox.vue";

const { DateTime } = require("luxon");

export default defineComponent({
  name: "SidebarCheckIn",

  emits: ["cancel", "deleted", "update:modelValue", "date_changed"],

  components: {
    BaseMultilineText,
    NumberInput,
    ImageViewer,
    Icon,
    BaseInput,
    DatePicker,
    BaseSelect,
    CustomLinkInput,
    BaseButton,
    BaseCheckBox,
  },

  props: {
    modelValue: {
      type: CheckIn,
      default: undefined,
    },
    distanceTravelledSoFar: {
      type: Number,
      default: 0,
    },
    adventure: {
      type: Adventure,
      default: undefined,
    },
    readOnly: Boolean,
  },

  computed: {
    nullAccommodationInstance() {
      return Accommodation.nullInstance();
    },
    formattedDate() {
      return this.checkIn.date.toLocaleString(DateTime.DATE_FULL);
    },
    iconOptions() {
      return constants.ICON_OPTIONS.ON_WHITE;
    },
    iconSize() {
      return constants.ICONSIZE.MEDIUM;
    },
    isStart() {
      return this.checkIn.type == "start";
    },
    image() {
      if (this.checkIn.images && this.checkIn.images.count >= 1) {
        return this.checkIn.images[0]
      }
      return null;
    },
    checkIn: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },

  data() {
    return {
      accommodationLookups: [],
      selectedAccommodationKey: null,
      isFirstLoad: true,
      customLinkData: {
        type: CustomLink,
        default: new CustomLink("a", "b", "c"),
      },
    };
  },

  watch: {
    // 'checkIn.accommodation': function() {
    //   if (this.checkIn.accommodation.key == null) {
    //     this.checkIn.accommodation = null;
    //   }
    // },
    // checkIn() {
    //   this.isFirstLoad = true;
    //   this.updateAccommodationSelect();
    // },
    // 'checkIn.accommodation.key': function(key) {
    //   // find the accommodation instance that matches the selected key
    //   if (key == null) {
    //     this.checkIn.accommodation = null;
    //   } else {
    //     let accommodation = this.accommodationLookups.find( a => a.key == key );
    //     if (accommodation) {
    //       // update the checkin (will dirty the object for saving)
    //       this.checkIn.accommodation = accommodation;
    //     } else {
    //       console.log("What's up!");
    //     }
    //   }
    // },
    //selectedAccommodationKey: function(key) {
    // this will get called when a new checkin is populated for the database - don't want this to trigger a save
    // if (this.isFirstLoad) {
    //   this.isFirstLoad = false;
    //   return;
    // }
    // // find the accommodation instance that matches the selected key
    // if (key == null) {
    //   this.checkIn.accommodation = null;
    // } else {
    //   let accommodation = this.accommodationLookups.find( a => a.key == key );
    //   if (accommodation) {
    //     // update the checkin (will dirty the object for saving)
    //     this.checkIn.accommodation = accommodation;
    //   } else {
    //     console.log("What's up!");
    //   }
    // }
    //},
  },

  mounted() {
    this.initialiseView();
    this.updateAccommodationSelect();
  },

  methods: {
    deleteCustomLink(i) {
      let copy = [...this.checkIn.customLinks];
      copy.splice(i, 1);
      this.checkIn.customLinks = copy;
    },
    addCustomLink() {
      this.checkIn.customLinks = [
        ...this.checkIn.customLinks,
        new CustomLink(),
      ];
      //this.checkIn.customLinks.push(new CustomLink());
    },
    /**
     * Updates the select controls key, so the correct entry is shown.
     */
    updateAccommodationSelect() {
      if (this.checkIn.accommodation) {
        this.selectedAccommodationKey = this.checkIn.accommodation.key;
      } else {
        this.selectedAccommodationKey = null;
      }
    },

    initialiseView() {
      let vm = this;
      getAccommodationLookup()
        .then((lookups) => {
          vm.accommodationLookups = lookups;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // validateName(value) {
    //   return new Promise( function(resolve, reject) {
    //     if (value.length == 0) {
    //       reject('Must be non empty');
    //     } else {
    //       resolve(true);
    //     }
    //   })
    // },
    // handleDeleteCheckIn() {
    //   const id = this.checkIn.id;
    //   deleteCheckIn(id).then(() => {
    //     this.$emit("deleted", id);
    //   });
    // },

    handleCancelCheckIn() {
      this.$emit("cancel");
    },
  },
});
</script>

<style scoped>
.gridContainer {
  padding: 0px 20px 20px 20px;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 30px 1fr;
}

label {
  padding-top: 5px;
  grid-column: 1 / 2;
}

.entry {
  grid-column: 2 / 3;
}

.checkInTitle {
  width: 100%;
}

.buttonStrip {
  bottom: 0px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.delete {
  color: red;
}

.notes {
  width: 100%;
  height: 120px;
  resize: none;
  box-sizing: border-box;
}

.entry-readonly {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 2px 0px;
  padding-left: 10px;
}

.btn-addcustomlink {
  margin-left: 0px;
}
</style>
